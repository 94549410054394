import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import Header from '../components/Header';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import NewsItem from '../components/NewsItem';

const StyledContainer = styled.div`
  padding: 90px 5px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 40px);
`;

const StyledLayout = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .post-nav {
    display: flex;
    padding: 0;
    list-style: none;
    font-size: 1.2rem;
    height: 1.2rem;
    margin: 0 0 20px;
    li {
      text-align: right;
      padding: 0 10px;
      &:last-child {
        text-align: left;
      }
      &:only-child {
        text-align: center;
      }
      a {
        color: currentColor;
        text-decoration: none;
        transition: color 0.2s;
        &:hover {
          color: #f36f5f;
        }
      }
    }
  }
`;

class BlogIndex extends React.Component {
  render() {
    const { 
      pageContext: { previousPagePath, nextPagePath },
    } = this.props;

    const posts = get(this, 'props.data.posts.edges');
    const blogPosts = posts.filter(p => get(p, 'node.frontmatter.spoiler'));

    return (
      <Fragment>
        <SEO />
        <Header/>
        <StyledContainer>
          <StyledLayout>
            {blogPosts.map(({ node }) => (
              <NewsItem
                key={node.fields.slug}
                title={get(node, 'frontmatter.title')}
                url={node.fields.slug}
                image={node.frontmatter.image}
                date={node.frontmatter.date}
                timeToRead={node.timeToRead}
                spoiler={node.frontmatter.spoiler}
              />
            ))}
            <ul className="post-nav">
              {nextPagePath && (
                <li>
                  <Link to={nextPagePath} rel="next">← Older</Link>
                </li>
              )}
              {previousPagePath && (
                <li>
                  <Link to={previousPagePath} rel="prev">Newer →</Link>
                </li>
              )}
            </ul>
          </StyledLayout>
        </StyledContainer>
        <Footer />
      </Fragment>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            image
          }
        }
      }
    }
  }
`;